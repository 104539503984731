import { Big } from "@simplicate/api-client";
import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import classNames from "classnames";
import { Column } from "primereact/column";
import { DataTable, DataTableRowReorderEvent, DataTableSelectionMultipleChangeEvent } from "primereact/datatable";
import { useState } from "react";
import { HourTypeInForm } from "../../useProjectServiceForm";
import { HourTypeActionDropdown } from "./HourTypeActionDropdown";
import styles from "./HourTypeGrid.module.scss";
import { HourTypeGridFooter } from "./HourTypeGridFooter";
import { HourTypeSelect } from "./HourTypeSelect";

const getStyleClassForColumn = (index: number) => {
  switch (index) {
    case 2:
      return styles.labelColumn;
    case 3:
      return styles.amountColumn;
    case 4:
      return styles.tariffColumn;
    case 5:
      return styles.totalColumn;
    case 6:
      return styles.actionButtonColumn;
    default:
      return undefined;
  }
};

type HourTypeGridProps = {
  value?: HourTypeInForm[];
  onAmountChange: (identifier: string, amount: number | undefined) => void;
  onTariffChange: (identifier: string, tariff: Big | undefined) => void;
  onAddNewHourTypeEntry: () => void;
  onConfirmNewHourTypeEntry: (entry: HourTypeInForm) => void;
  onCancelNewHourTypeEntry: () => void;
  onRemoveHourTypeEntry: (identifier: string) => void;
  disabled?: boolean;
};

export const HourTypeGrid = ({
  value = [],
  onAmountChange,
  onTariffChange,
  onAddNewHourTypeEntry,
  onConfirmNewHourTypeEntry,
  onCancelNewHourTypeEntry,
  onRemoveHourTypeEntry,
  disabled = false,
}: HourTypeGridProps) => {
  const { t } = useTranslation("project_services");

  const [selectedRows, setSelectedRows] = useState<HourTypeInForm[]>([]);

  const { totalAmount, totalMonetaryValue } = value.reduce(
    (totals, current) => {
      totals.totalAmount += current.amount ?? 0;
      totals.totalMonetaryValue = totals.totalMonetaryValue.add(current.total.amount ?? 0);

      return totals;
    },
    { totalAmount: 0, totalMonetaryValue: Big(0) },
  );

  const isAddingHourType = value.some((hourType) => hourType.isNewEntry);

  /* istanbul ignore next -- WIP, test placeholder already exists */
  const onSelectionChange = (event: DataTableSelectionMultipleChangeEvent<HourTypeInForm[]>) => {
    setSelectedRows(event.value);
  };

  /* istanbul ignore next -- WIP, test placeholder already exists */
  const onRowReorder = (event: DataTableRowReorderEvent<HourTypeInForm[]>) => {
    console.log(event);
  };

  return (
    <DataTable
      data-testid="hour-type-grid"
      value={value}
      dataKey="identifier"
      selectionMode="checkbox"
      selection={selectedRows}
      onSelectionChange={onSelectionChange}
      reorderableRows
      onRowReorder={onRowReorder}
      emptyMessage={t("empty_hour_type_grid_message")}
      footer={
        <HourTypeGridFooter
          totalAmount={totalAmount}
          totalMonetaryValue={totalMonetaryValue}
          onAddHourType={onAddNewHourTypeEntry}
          disableAddHourTypeButton={isAddingHourType || disabled}
        />
      }
      pt={{
        headerRow: () => ({
          className: styles.headerRow,
        }),
        bodyRow: () => ({
          className: styles.row,
        }),
        column: {
          headerCell: (options) => {
            /* istanbul ignore if -- options being undefined is not applicable here */
            if (!options) {
              return;
            }

            const { context } = options;

            return { className: classNames(styles.headerCell, getStyleClassForColumn(context.index)) };
          },
        },
      }}
    >
      <Column selectionMode={!isAddingHourType ? "multiple" : undefined} />
      {/* TODO custom orderIcon */}
      <Column rowReorder={!isAddingHourType ? true : undefined} />
      <Column
        header={t("hour_type_label_header")}
        field="label"
        className={styles.labelColumn}
        body={({ isNewEntry, name }: HourTypeInForm) =>
          isNewEntry ? (
            <HourTypeSelect
              hourTypesInForm={value}
              onConfirmNewEntry={onConfirmNewHourTypeEntry}
              onCancelNewEntry={onCancelNewHourTypeEntry}
            />
          ) : (
            name
          )
        }
      />
      <Column
        header={t("hour_type_amount_header")}
        field="amount"
        className={styles.amountColumn}
        body={({ isNewEntry, identifier, amount }: HourTypeInForm) =>
          !isNewEntry ? (
            <NumberFormat
              testId="input-hour-type-amount"
              displayType="input"
              variant="compact"
              value={amount ?? 0}
              decimalScale={2}
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ floatValue }) => onAmountChange(identifier, floatValue)
              }
              onBlur={() => {
                if (amount === undefined) {
                  onAmountChange(identifier, 0);
                }
              }}
            />
          ) : null
        }
      />
      <Column
        header={t("hour_type_tariff_header")}
        field="tariff"
        className={styles.tariffColumn}
        body={({ isNewEntry, identifier, tariff }: HourTypeInForm) =>
          !isNewEntry ? (
            <CurrencyFormat
              testId="input-hour-type-tariff"
              displayType="input"
              variant="compact"
              value={tariff?.amount?.toString()}
              decimalScale={2}
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ value }) => {
                  if (value.length === 0) {
                    return onTariffChange(identifier, undefined);
                  }

                  return onTariffChange(identifier, Big(value));
                }
              }
              onBlur={() => {
                if (tariff === undefined) {
                  onTariffChange(identifier, Big(0));
                }
              }}
            />
          ) : null
        }
      />
      <Column
        header={t("hour_type_total_header")}
        field="total"
        className={styles.totalColumn}
        body={({ isNewEntry, total }: HourTypeInForm) =>
          !isNewEntry ? <CurrencyFormat displayType="text" decimalScale={2} value={total.amount.toString()} /> : null
        }
      />
      <Column
        body={({ identifier, isNewEntry }: HourTypeInForm) =>
          !isNewEntry ? (
            <HourTypeActionDropdown
              identifier={identifier}
              onClickMoveToTop={() => {}}
              onClickMoveToBottom={() => {}}
              onClickRemove={onRemoveHourTypeEntry}
            />
          ) : null
        }
        className={styles.actionButtonColumn}
      />
    </DataTable>
  );
};
