import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFolderTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowToBottom,
  faBell,
  faBullhorn,
  faEllipsisVertical,
  faFileExport,
  faQuestionCircle,
  faSpinner as faSpinnerRegular,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faGripVertical,
  faInfoCircle,
  faPowerOff,
  faSearch,
  faSpinner as faSpinnerSolid,
  faTimes,
  faTrashAlt,
  faUser,
  faWandMagicSparkles,
  faUserGroupSimple,
  faSort,
  faEye,
  faList,
  faPlus,
  faMinus,
  faHourglass,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ICON_MAP = {
  arrowDown: faArrowDown,
  arrowLeft: faArrowLeft,
  arrowRight: faArrowRight,
  arrowUp: faArrowUp,
  bell: faBell,
  bullhorn: faBullhorn,
  check: faCheck,
  calendarAlt: faCalendarAlt,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  copy: faCopy,
  download: faArrowToBottom,
  ellipsisVertical: faEllipsisVertical,
  euroSign: faEuroSign,
  exclamationCircle: faExclamationCircle,
  exclamationTriangle: faExclamationTriangle,
  eye: faEye,
  list: faList,
  fileExport: faFileExport,
  folderTimes: faFolderTimes,
  plus: faPlus,
  minus: faMinus,
  grip: faGripVertical,
  infoCircle: faInfoCircle,
  powerOff: faPowerOff,
  questionCircle: faQuestionCircle,
  search: faSearch,
  sort: faSort,
  spinner: { default: faSpinnerRegular, solid: faSpinnerSolid },
  times: faTimes,
  trash: faTrashAlt,
  user: faUser,
  wand: faWandMagicSparkles,
  userGroup: faUserGroupSimple,
  hourglass: faHourglass,
} as const satisfies Record<
  string,
  IconDefinition | (Record<"default", IconDefinition> & Record<string, IconDefinition>)
>;

export const SUPPORTED_ICONS = Object.keys(ICON_MAP) as IconName[];

export type IconName = keyof typeof ICON_MAP;

/**
 * String union of all available variants for a given icon.
 *
 * @example
 * ```ts
 * ICON_MAP = {
 *   exampleIcon: faCircleInfo,
 *   iconWithVariants: { default: faBell, solid: faCheck },
 * }
 *
 * IconVariant<"exampleIcon"> // never
 * IconVariant<"iconWithVariants"> // "default" | "solid"
 * ```
 */
type IconVariant<TKey extends IconName> = (typeof ICON_MAP)[TKey] extends infer TValue extends {
  default: IconDefinition;
}
  ? keyof TValue
  : never;

type IconProps<TKey extends IconName> = Omit<React.ComponentProps<typeof FontAwesomeIcon>, "icon"> & {
  testId?: string;
  icon: TKey;
  variant?: IconVariant<TKey>;
};

export function getIcon<TKey extends IconName>(icon: TKey, variant?: IconVariant<TKey>): IconDefinition {
  const resolved = ICON_MAP[icon];

  if (Object.keys(resolved).includes("default")) {
    return (resolved as Record<IconVariant<TKey> | "default", IconDefinition>)[variant ?? "default"];
  }

  return resolved as IconDefinition;
}

export const Icon = <TKey extends IconName>({ icon, variant, testId, ...props }: IconProps<TKey>) => {
  const resolvedIcon = getIcon(icon, variant);

  return <FontAwesomeIcon icon={resolvedIcon} {...props} data-testid={testId} />;
};
