import classnames from "classnames";
import { Icon, IconName } from "../Icon";
import styles from "./Pill.module.scss";

type CommonProps = {
  variant?: "danger" | "invisible" | "invisibleDanger" | "primary" | "secondary" | "subtle";
  size?: "medium" | "small";
  disabled?: boolean;
  testId?: string;
};

type TextProps = {
  text: string;
  icon?: IconName;
};

type IconProps = {
  icon: IconName;
  text?: string;
};

export type PillProps = CommonProps & (IconProps | TextProps);

export const Pill = ({ text, icon, variant = "primary", size = "medium", disabled = false, testId }: PillProps) => {
  return (
    <div
      className={classnames(styles.pill, styles[variant], styles[size], disabled && styles.disabled)}
      data-testid={testId}
    >
      {icon && <Icon icon={icon} className={styles.pillIcon} testId={`${testId}-icon`} />}
      {text && <span className={styles.text}>{text}</span>}
    </div>
  );
};
