import { useGetUnreadNotificationCountQuery } from "@simplicate/api-client";
import { useAppDispatch, setHeader } from "@simplicate/state";
import { useEffect } from "react";

const DEFAULT_TITLE_SUFFIX = " | Simplicate";

export const useBrowserTitleUpdater = (title: string) => {
  const dispatch = useAppDispatch();
  const { data } = useGetUnreadNotificationCountQuery(undefined);

  const unReadNotification = data?.data.unread;

  const browserTabTitle =
    unReadNotification && unReadNotification > 0
      ? `(${unReadNotification}) ${title}` + DEFAULT_TITLE_SUFFIX
      : title + DEFAULT_TITLE_SUFFIX;

  useEffect(() => {
    dispatch(setHeader({ browserTabTitle: browserTabTitle }));
  }, [browserTabTitle, dispatch, title, unReadNotification]);

  return browserTabTitle;
};
