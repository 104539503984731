import { PropsWithChildren } from "react";
import styles from "./SubHeader.module.scss";
import { useBrowserTitleUpdater } from "./useBrowserTitleUpdater";

type SubHeaderProps = PropsWithChildren<{
  title: string;
  testId?: string;
}>;

export const SubHeader = ({ title, testId, children }: SubHeaderProps) => {
  useBrowserTitleUpdater(title);

  return (
    <div className={styles.container} data-testid={testId}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.tags}>{children}</div>
    </div>
  );
};
