import styles from "./Checkbox.module.scss";

type CheckboxProps = {
  name: string;
  label: string;
  onChange: (newValue: boolean) => void;
  value: boolean | "indeterminate";
  disabled?: boolean;
  testId?: string;
};

export const Checkbox = ({ name, label, disabled, onChange, value, testId }: CheckboxProps) => {
  return (
    <div className={styles.checkbox}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className={styles.input}
        onChange={(event) => onChange(event.target.checked)}
        checked={value === "indeterminate" ? false : value}
        disabled={disabled}
        ref={(input) => {
          if (input) {
            input.indeterminate = value === "indeterminate";
          }
        }}
        data-testid={testId}
      />
      <div className={styles.checkboxInfoContainer}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  );
};
