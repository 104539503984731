import { HourTypeInCreateServiceBody, HourTypeInService, Big } from "@simplicate/api-client";
import { HourTypeInForm, ValidHourTypeInForm } from "./useProjectServiceForm";

const ROUND_HALF_UP = 1;

export const transformToCreateServiceHourType = (hourTypeInForm: ValidHourTypeInForm): HourTypeInCreateServiceBody => ({
  defaultHourTypeIdentifier: hourTypeInForm.identifier,
  tariff: {
    amount: hourTypeInForm.tariff.amount.toFixed(2, ROUND_HALF_UP),
    currency: hourTypeInForm.tariff.currency,
  },
  amountOfHours: hourTypeInForm.amount,
  isInvoicable: undefined, // TODO add input to toggle this
});

export const transformToHourTypeForm = (hourTypeFromApi: HourTypeInService): HourTypeInForm => ({
  identifier: hourTypeFromApi.defaultHourType.identifier,
  name: hourTypeFromApi.defaultHourType.name,
  amount: hourTypeFromApi.amountOfHours,
  tariff: {
    amount: Big(hourTypeFromApi.tariff.amount),
    currency: hourTypeFromApi.tariff.currency,
  },
  total: {
    amount: Big(hourTypeFromApi.tariff.amount).mul(hourTypeFromApi.amountOfHours),
    currency: hourTypeFromApi.tariff.currency,
  },
});
