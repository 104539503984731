import { useFeatureFlag } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { LinkButton } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { ProjectDetailsPageTabs } from "../ProjectDetailsPageTabs";

export const ProjectDetailsServicePageTabs = () => {
  const { t } = useTranslation("general");
  const { id } = useParams<{ id: string }>();

  const { enabled } = useFeatureFlag("projects-new-service-ui");

  return (
    <ProjectDetailsPageTabs
      actionButtons={
        enabled && (
          <LinkButton text={t("buttons.new")} to={`/projects/${id}/services/addservice`} testId="new-service-button" />
        )
      }
    />
  );
};
