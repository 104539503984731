import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetOrganizationQuery } from "@simplicate/api-client";
import { SubHeader } from "@simplicate/top-bar";
import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { useParams } from "react-router-dom";

export const OrganizationSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("top_bar");

  const { data: organization } = useGetOrganizationQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  if (!organization) {
    return null;
  }

  const organizationName = organization.data.name;
  const relationTypeLabel = organization.data.relation_type?.label;
  const relationTypeColor = organization.data.relation_type?.color;
  const organizationIsActive = organization.data.is_active;
  const relationNumber = organization.data.relation_number;

  const title = relationNumber ? `${relationNumber}. ${organizationName}` : organizationName;

  return (
    <SubHeader title={title}>
      {relationTypeLabel && (
        <Tag
          text={relationTypeLabel}
          color={relationTypeColor}
          testId="relation-type"
          size="small"
          variant="color"
          isElevated
        />
      )}
      {!organizationIsActive && <Tag text={t("inactive")} isElevated testId="relation-type" size="small" />}
      <Tag text={t("organization")} testId="relation-type" size="small" isElevated />
    </SubHeader>
  );
};
