import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { useTranslation } from "@simplicate/translations";
import styles from "./Poc1Widget.module.scss";

export const Poc1Widget = ({
  startDate,
  endDate,
  teamName,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  teamName: string | undefined;
}) => {
  const { t } = useTranslation("cube_dev");

  let query: Query = { measures: ["cube_time_entries.sum_time_entry_amount_hours"] };
  if (startDate && endDate) {
    query = {
      ...query,
      timeDimensions: [
        {
          dimension: "cube_time_entries.dim_time_entry_timestamp_start",
          dateRange: [startDate, endDate],
        },
      ],
    };
  }

  if (teamName) {
    query = {
      ...query,
      filters: [
        {
          member: "cube_teams.dim_team_name",
          operator: "equals",
          values: [teamName],
        },
      ],
    };
  }

  const { resultSet, isLoading, error } = useCubeQuery(query);

  if (error) {
    return <div>{error.message}</div>;
  }
  if (isLoading || !resultSet) {
    return null;
  }

  return (
    <div className={styles.cubeDevPageCard}>
      {t("Total registered")}
      <br />
      <br />
      {resultSet.tablePivot()?.[0]?.["cube_time_entries.sum_time_entry_amount_hours"]}
    </div>
  );
};
