import { Link, LinkProps } from "react-router-dom";
import styles from "./LinkButton.module.scss";
import { Pill, PillProps } from "./Pill";

type LinkButtonProps = LinkProps &
  PillProps & {
    testId?: string;
  };

export const LinkButton = ({ testId, disabled, ...props }: LinkButtonProps) => {
  return disabled ? (
    <div className={styles.link} data-testid={testId}>
      <Pill {...props} disabled testId={`${testId}-content`} />
    </div>
  ) : (
    <Link {...props} className={styles.link} data-testid={testId}>
      <Pill {...props} testId={`${testId}-content`} />
    </Link>
  );
};
