import { DOMAttributes } from "react";
import styles from "./Button.module.scss";
import { Pill, PillProps } from "./Pill";

type ButtonProps = PillProps & {
  ariaLabel?: string;
  disabled?: HTMLButtonElement["disabled"];
  onClick?: DOMAttributes<HTMLButtonElement>["onClick"];
  testId?: string;
  type?: HTMLButtonElement["type"];
  form?: string;
};

export const Button = ({ disabled = false, onClick, testId, ariaLabel, type, form, ...pillProps }: ButtonProps) => {
  return (
    <button
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      className={styles.button}
      data-testid={testId}
      type={type}
      form={form}
    >
      <Pill {...pillProps} disabled={disabled} testId={`${testId}-content`} />
    </button>
  );
};
