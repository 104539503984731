import { FeatureFlagSwitch } from "@simplicate/api-client";
import { CubeDevPage } from "@simplicate/pages";
import { Page } from "@simplicate/ui";
import { Navigate } from "react-router-dom";
import { LegacyWebComponent } from "../../components";
import { RouteObject } from "../types";

export const businessIntelligenceRoutes: RouteObject[] = [
  {
    path: "business-intelligence",
    element: <LegacyWebComponent />,
    handle: { theme: "business-intelligence", headerTitle: "module_business_intelligence" },
  },
  {
    path: "business-intelligence-cube-dev",
    element: (
      <FeatureFlagSwitch
        featureFlag="business-intelligence-cubecloud"
        ifEnabled={<CubeDevPage />}
        ifDisabled={<Navigate to="/404" />}
      />
    ),
    handle: { theme: "business-intelligence", headerTitle: "module_business_intelligence" },
  },
  {
    path: "business-intelligence-embeddable",
    element: (
      <FeatureFlagSwitch
        featureFlag="business-intelligence-embeddable"
        ifEnabled={<Page>Someday... Embeddable!!!</Page>}
        ifDisabled={<Navigate to="/404" />}
      />
    ),
    handle: { theme: "business-intelligence", headerTitle: "module_business_intelligence" },
  },
];
