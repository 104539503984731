import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetHourTypeQuery, useGetHourTypesQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button, Select } from "@simplicate/ui";
import { useState } from "react";
import { HourTypeInForm } from "../../../useProjectServiceForm";
import styles from "./HourTypeSelect.module.scss";

type HourTypeSelectProps = {
  onConfirmNewEntry: (entry: HourTypeInForm) => void;
  onCancelNewEntry: () => void;
  hourTypesInForm: HourTypeInForm[] | undefined;
};

export const HourTypeSelect = ({ onConfirmNewEntry, onCancelNewEntry, hourTypesInForm }: HourTypeSelectProps) => {
  const { t } = useTranslation("project_services");
  const [selectedHourTypeIdentifier, setSelectedHourTypeIdentifier] = useState<string | undefined>(undefined);
  const { data: options } = useGetHourTypesQuery();
  const { data: selectedHourType } = useGetHourTypeQuery(
    selectedHourTypeIdentifier ? selectedHourTypeIdentifier : skipToken,
  );

  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.identifier,
  }));

  const isOptionDisabled = (option: { label: string; value: string }) => {
    // TODO: improve coverage, or just remove the ability for hourTypeInForm to be undefined
    /* istanbul ignore next -- branching is not covered */
    return hourTypesInForm?.some((hourType) => hourType.identifier === option.value) ?? false;
  };

  return (
    <div className={styles.container}>
      <div>
        <Select
          testId="hour-type-select"
          value={selectedHourTypeIdentifier}
          options={compatibleOptions}
          placeholder={t("hour_type_placeholder")}
          onSelect={setSelectedHourTypeIdentifier}
          optionDisabled={isOptionDisabled}
        />
      </div>
      <div>
        <Button
          testId="confirm-new-hour-type"
          variant="secondary"
          icon="check"
          disabled={!selectedHourTypeIdentifier}
          onClick={() =>
            selectedHourType &&
            onConfirmNewEntry({
              identifier: selectedHourType.identifier,
              name: selectedHourType.name,
              amount: 1,
              tariff: selectedHourType.tariff,
              total: selectedHourType.tariff,
            })
          }
        />
      </div>
      <div>
        <Button variant="secondary" icon="times" onClick={onCancelNewEntry} />
      </div>
    </div>
  );
};
