import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Module } from "@simplicate/utils";
import { clearMessages, iframeMessageReceived } from "../iFrameSlice";

export type HeaderState = {
  title: string;
  theme: Module;
  browserTabTitle?: string;

  /**
   * When true, either the iframe environment or the legacy web component has requested an modal backdrop to be
   * overlayed the common navigation UI components, such as the header.
   */
  showOverlay: boolean;
};

function updateBrowserTabTitle(title: string) {
  document.title = title;
}

const initialState: HeaderState = {
  title: "",
  theme: "dashboard",
  browserTabTitle: "",
  showOverlay: false,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<Partial<HeaderState>>) => ({
      ...state,
      ...action.payload,
      ...(action.payload.browserTabTitle ? updateBrowserTabTitle(action.payload.browserTabTitle) : {}),
    }),
  },
  extraReducers: /* istanbul ignore next */ (builder) => {
    builder.addCase(iframeMessageReceived, (state, action) => {
      action.payload.params.forEach((message) => {
        if (message.action === "IFRAME_SUBHEADER_ENABLED") {
          state.showOverlay = !message.payload.enabled;
        }
      });
    });
    builder.addCase(clearMessages, (state) => {
      state.showOverlay = false;
    });
  },
});

export const { setHeader } = headerSlice.actions;

export const headerReducer = headerSlice.reducer;
