import { colorCommonGrey0, colorCommonGrey800 } from "@simplicate-software/design-tokens";
import classnames from "classnames";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { getContrastColor } from "../../utils/getContrastColor";
import { Icon, IconName } from "../Icon";
import styles from "./Tag.module.scss";

const BACKGROUND_VARIABLE = "--background-color" as keyof CSSProperties;
const FOREGROUND_VARIABLE = "--text-color" as keyof CSSProperties;

type TagProps = {
  text: string;
  variant?: "color" | "dark" | "default" | "light";
  color?: string;
  size?: "medium" | "small";
  onClose?: () => void;
  icon?: IconName;
  testId?: string;
  href?: string;
  isElevated?: boolean;
};

const variantMap = {
  default: colorCommonGrey800,
  color: colorCommonGrey0,
  light: colorCommonGrey800,
  dark: colorCommonGrey0,
} as const;

export const Tag = ({
  text,
  isElevated = false,
  size = "medium",
  variant = "default",
  icon,
  onClose,
  testId,
  color,
  href,
}: TagProps) => {
  const contrastColor = color ? getContrastColor(color) : variantMap[variant as keyof typeof variantMap];
  const tagStyle =
    variant === "color" ? { [BACKGROUND_VARIABLE]: color, [FOREGROUND_VARIABLE]: contrastColor } : undefined;

  return (
    <div
      style={tagStyle}
      className={classnames(styles.tag, isElevated && styles.elevated, styles[variant], styles[size])}
    >
      {/*
       * TODO: Use the link element as top level component to prevent having to inherit all the container styles. We
       * need to make a decision about whether the close button should be supported for tags that link to somewhere.
       */}
      {href ? (
        <Link data-testid={testId} className={styles.tagButton} to={href}>
          {icon && (
            <span className={styles.icon}>
              <Icon icon={icon} />
            </span>
          )}
          <span>{text}</span>
        </Link>
      ) : (
        <>
          {icon && (
            <span className={styles.icon}>
              <Icon icon={icon} />
            </span>
          )}
          <span>{text}</span>
        </>
      )}

      {onClose && (
        <button className={classnames(styles.close, styles.icon)} data-testid="tag-close-button" onClick={onClose}>
          <Icon icon="times" />
        </button>
      )}
    </div>
  );
};
