import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableProps, DataTableValueArray } from "primereact/datatable";
import { Icon } from "../Icon";
import styles from "./Table.module.scss";

export type TableProps<TValue extends DataTableValueArray> = DataTableProps<TValue> & {
  columns: ColumnProps[];
  testId?: string;
};

export const Table = <TValue extends DataTableValueArray>({ columns, testId, ...props }: TableProps<TValue>) => {
  return (
    <DataTable sortIcon={<Icon icon="sort" fixedWidth className={styles.sortIcon} />} data-testid={testId} {...props}>
      {columns.map((columnProps) => (
        <Column key={columnProps.field} className={styles.data} headerClassName={styles.header} {...columnProps} />
      ))}
    </DataTable>
  );
};
