import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";

export type VATCode = {
  identifier: string;
  name: string;
};

const tags = createTagTypes({
  tagPrefix: "VATCode",
  tags: ["list"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getVATCodes: builder.query<VATCode[], void>({
      query: () => ({
        url: "/serviceConfiguration.vatCode.listAvailable",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetVATCodesQuery } = endpoints;
