import { skipToken, useGetProjectQuery } from "@simplicate/api-client";
import { SubHeader } from "@simplicate/top-bar";
import { Tag } from "@simplicate/ui";
import { colorCommonGreen500, colorCommonRed500, colorCommonGrey200 } from "@simplicate-software/design-tokens";
import { useParams } from "react-router-dom";
import { RelationTag } from "../RelationTag";

const Project_STATUS_ACTIVE_ID = "projectstatus:5d86b75e14c74a9b";
const Project_STATUS_CLOSED_ID = "projectstatus:5014e9605810267a";

export const ProjectSubHeader = () => {
  const { id } = useParams<{ id: string }>();

  const { data: project } = useGetProjectQuery(id ?? skipToken);

  const projectName = project?.data?.name;
  const projectNumber = project?.data?.project_number;
  const relationName = project?.data?.organization?.name ?? project?.data?.person?.full_name;
  const organizationId = project?.data?.organization?.id;
  const personId = project?.data?.person?.id;
  const status = project?.data?.project_status?.label;

  const statusId = project?.data?.project_status?.id;

  const statusColor =
    statusId === Project_STATUS_ACTIVE_ID
      ? colorCommonGreen500
      : statusId === Project_STATUS_CLOSED_ID
        ? colorCommonRed500
        : colorCommonGrey200;

  if (!projectName) return null;

  const title = projectNumber ? `${projectNumber}. ${projectName}` : projectName;

  return (
    <SubHeader title={title}>
      <RelationTag organizationId={organizationId} personId={personId} name={relationName} />
      {status && <Tag testId="status-tag" isElevated text={status} size="small" color={statusColor} variant="color" />}
    </SubHeader>
  );
};
