import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Button } from "@simplicate/ui";
import { memo } from "react";
import styles from "./HourTypeGridFooter.module.scss";

type HourTypeGridFooterProps = {
  totalAmount: number;
  totalMonetaryValue: Big;
  disableAddHourTypeButton: boolean;
  onAddHourType: () => void;
};

export const HourTypeGridFooter = memo(function HourTypeGridFooter({
  totalAmount,
  totalMonetaryValue,
  disableAddHourTypeButton,
  onAddHourType,
}: HourTypeGridFooterProps) {
  const { t } = useTranslation("project_services");

  return (
    <div data-testid="hour-type-grid-footer" className={styles.footer}>
      <div className={styles.buttonContainer}>
        {/* TODO proper '+' icon */}
        <Button
          testId="add-new-hour-type"
          text={t("add_hour_type_button")}
          variant="secondary"
          onClick={onAddHourType}
          disabled={disableAddHourTypeButton}
        />
      </div>
      <span className={styles.budgetLabel}>Budget</span>
      <span className={styles.amountValue}>{totalAmount}</span>
      <span className={styles.amountLabel}>{t("hour", { count: totalAmount })}</span>
      <span className={styles.totalValue}>
        <CurrencyFormat value={totalMonetaryValue.toString()} />
      </span>
      <div className={styles.spacer} />
    </div>
  );
});
