import { GroupedServicesManager } from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { Page, Button } from "@simplicate/ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AddServiceGroupModal } from "./components/AddServiceGroupModal";
import { useSalesServicesPageData } from "./SalesServicesPage.hooks";
import styles from "./SalesServicesPage.module.scss";

export const SalesServicesPage = () => {
  const { id: salesId } = useParams<{ id: string }>();
  const { treeData, setAndSaveTreeData, rootId, groupServicesByInvoiceMethod } = useSalesServicesPageData(salesId);
  const { t } = useTranslation("sales_services");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  return (
    <Page>
      <h2 className={styles.title}>{t("page_title")}</h2>
      <Button
        variant="primary"
        size="medium"
        onClick={() => {
          setModalKey((prev) => prev + 1);
          setModalOpen(true);
        }}
        text={t("add_service_button")}
        icon="chevronDown"
      />
      {treeData && (
        <GroupedServicesManager
          treeData={treeData}
          onChangeTree={setAndSaveTreeData}
          rootId={rootId}
          onAutoGroup={groupServicesByInvoiceMethod}
        />
      )}
      {salesId && (
        <AddServiceGroupModal salesId={salesId} isOpen={modalOpen} onClose={() => setModalOpen(false)} key={modalKey} />
      )}
    </Page>
  );
};
