import { RenderParams } from "@minoru/react-dnd-treeview";
import { useTranslation } from "@simplicate/translations";
import { Button, Dropdown, Icon, List, ListItem } from "@simplicate/ui";
import classNames from "classnames";
import { useState } from "react";
import { ServiceGroupNodeModel, ServiceGroupType } from "../GroupedServicesManager.types";
import styles from "./ServiceGroup.module.scss";
import { ServiceRowHeader } from "./ServiceRowHeader";

type ServiceGroupProps = Partial<RenderParams> & {
  node: ServiceGroupNodeModel;
  onDuplicateGroup?: () => void;
  onAutoGroup?: () => void;
};

export const ServiceGroup = ({
  node: { text, data },
  hasChild,
  isDropTarget,
  onDuplicateGroup,
  onAutoGroup,
}: ServiceGroupProps) => {
  const { t } = useTranslation("grouped_services_manager");
  const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);
  const isUngroupedContainer = data?.groupType === ServiceGroupType.UNGROUPED;

  return (
    <div className={styles.serviceGroup}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <span className={classNames(styles.grip, { [styles.hidden as string]: isUngroupedContainer })}>
            <Icon icon="grip" />
          </span>
          <h2 className={styles.groupName}>{text}</h2>
        </div>
        <div className={styles.headerRight}>
          {isUngroupedContainer
            ? onAutoGroup && (
                <Button variant="secondary" icon="wand" text={t("group_by_invoice_method")} onClick={onAutoGroup} />
              )
            : (onDuplicateGroup || data?.deleteCallback) && (
                <div className={styles.buttonDropdownContainer}>
                  <Button
                    variant="subtle"
                    icon="ellipsisVertical"
                    ariaLabel={t("actions")}
                    onClick={
                      /* c8 ignore start */
                      /* istanbul ignore next */ () => setActionsDropdownOpen(!actionsDropdownOpen)
                      /* c8 ignore end */
                    }
                  />
                  <div className={styles.dropdownContainer}>
                    <Dropdown
                      isOpen={actionsDropdownOpen}
                      onClickOutside={
                        /* c8 ignore start */
                        /* istanbul ignore next */ () => setActionsDropdownOpen(false)
                        /* c8 ignore end */
                      }
                      width="auto"
                      hasPadding={false}
                    >
                      <List>
                        {onDuplicateGroup && (
                          <ListItem>
                            <Button variant="invisible" text={t("duplicate")} icon="copy" onClick={onDuplicateGroup} />
                          </ListItem>
                        )}
                        {data?.deleteCallback && (
                          <ListItem>
                            <Button
                              variant="invisibleDanger"
                              text={t("delete")}
                              icon="trash"
                              onClick={data?.deleteCallback}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Dropdown>
                  </div>
                </div>
              )}
        </div>
      </div>
      <ServiceRowHeader />
      {!hasChild && (
        <div className={classNames(styles.emptyMessage, { [styles.dropTarget as string]: isDropTarget })}>
          {t("empty_service_group_message")}
        </div>
      )}
    </div>
  );
};
