// @
import cube from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import { useTranslation } from "@simplicate/translations";
import { ColumnsTemplate, Page, Input, Datepicker } from "@simplicate/ui";
import { useEffect, useState } from "react";
import { Poc1Table } from "./dashboard/Poc1Table";
import { Poc1Widget } from "./dashboard/Poc1Widget";
import { Poc1Widget2 } from "./dashboard/Poc1Widget2";

type ApiConfiguration = {
  token?: string;
  endpoint?: string;
};

// TODO add testcases before leaving POC state
export const CubeDevPage = () => {
  const { t } = useTranslation("cube_dev");
  const [config, setConfig] = useState<ApiConfiguration>();
  const [dateStart, setStart] = useState<string>("2024-02-01");
  const [dateEnd, setEnd] = useState<string>("2024-04-01");
  const [teamName, setTeam] = useState<string>("");

  useEffect(() => {
    if (config?.token && config?.endpoint) {
      return;
    }

    fetch(`/api/v2/cubeDev/jwt`)
      .then((res) => res.json())
      .then((r: { data: { jwt: string; endpoint: string } }) => {
        setConfig({ token: r.data.jwt, endpoint: r.data.endpoint });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [config, config?.token, config?.endpoint]);

  if (!config?.token || !config?.endpoint) {
    return null;
  }

  const cubeApi = cube(config.token, {
    apiUrl: config?.endpoint,
  });

  return (
    <Page>
      <CubeProvider cubeApi={cubeApi}>
        <ColumnsTemplate>
          <ColumnsTemplate.Column columnSpan={2}>
            <Datepicker label={t("date_from")} onChange={(value) => setStart(value?.toDateString() ?? "")} />
          </ColumnsTemplate.Column>
          <ColumnsTemplate.Column columnSpan={2}>
            <Datepicker label={t("date_to")} onChange={(value) => setEnd(value?.toDateString() ?? "")} />
          </ColumnsTemplate.Column>
          <ColumnsTemplate.Column columnSpan={2}>
            <Input
              type="text"
              label={t("team_name")}
              value={teamName}
              onChange={({ target: { value } }) => setTeam(value)}
            />
          </ColumnsTemplate.Column>
        </ColumnsTemplate>
        <div style={{ height: "1rem", display: "block" }} />
        <ColumnsTemplate>
          <ColumnsTemplate.Column columnSpan={2}>
            <Poc1Widget startDate={dateStart} endDate={dateEnd} teamName={teamName} />
          </ColumnsTemplate.Column>
          <ColumnsTemplate.Column columnSpan={2}>
            <Poc1Widget2 />
          </ColumnsTemplate.Column>
        </ColumnsTemplate>
        <div style={{ height: "1rem", display: "block" }} />
        <Poc1Table startDate={dateStart} endDate={dateEnd} teamName={teamName} />
      </CubeProvider>
    </Page>
  );
};
