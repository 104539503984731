import BigJs from "big.js";

export type Big = BigJs;
export const Big = BigJs;

export type MoneyFromAPI = {
  amount: string;
  currency: "EUR";
};

export type Money = Omit<MoneyFromAPI, "amount"> & {
  amount: Big;
};

function moneyStringToBig(money: string): Big {
  return Big(money);
}

export const transformMoneyFromAPIToMoney = (money: MoneyFromAPI): Money => ({
  amount: moneyStringToBig(money.amount),
  currency: money.currency,
});
