import { useAppDispatch, setHeader } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { useEffect } from "react";
import { useMatches } from "../../../routing/useMatches";

export const useHeaderEventListener = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("top_bar");

  const matches = useMatches();

  const headerTitle = matches.findLast((match) => match.handle?.headerTitle)?.handle?.headerTitle;

  useEffect(() => {
    if (headerTitle) {
      dispatch(setHeader({ title: t(headerTitle) }));
    }
  }, [headerTitle, dispatch, t]);
};
