import { useTranslation } from "@simplicate/translations";
import { LinkButton } from "@simplicate/ui";
import { differenceInMinutes } from "date-fns";
import { memo, useEffect, useState } from "react";
import styles from "./SessionExpiryNotification.module.scss";

// Determine amount of minutes remaining every 15 seconds to ensure it is approximately accurate
const UPDATE_INTERVAL = 15 * 1000; // 15 seconds
const LOGOUT_URL = "/site/logout";

type SessionExpiryNotificationProps = {
  exprirationDate: number;
};

export const SessionExpiryNotification = memo(function SessionExpiryNotification({
  exprirationDate,
}: SessionExpiryNotificationProps) {
  const { t } = useTranslation("general");
  const [remainingMinutes, setRemainingMinutes] = useState(differenceInMinutes(exprirationDate, Date.now()));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingMinutes(differenceInMinutes(exprirationDate, Date.now()));
    }, UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [exprirationDate]);

  return (
    <div className={styles.sessionExpiryNotification}>
      {remainingMinutes > 0 ? (
        <span>{t("session_about_to_expire", { count: remainingMinutes })}</span>
      ) : (
        <span>{t("session_about_to_expire_soon")}</span>
      )}
      <LinkButton to={LOGOUT_URL} text={t("session_about_to_expire_logout_now")} variant="subtle" />
    </div>
  );
});
