import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { useTranslation } from "@simplicate/translations";
import { Table } from "@simplicate/ui";

export const Poc1Table = ({
  startDate,
  endDate,
  teamName,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  teamName: string | undefined;
}) => {
  const { t } = useTranslation("cube_dev");

  let query: Query = {
    dimensions: ["cube_employees.dim_employee_name"],
    measures: ["cube_time_entries.sum_time_entry_amount_hours"],
    order: { "cube_employees.dim_employee_name": "asc" },
  };

  if (startDate && endDate) {
    query = {
      ...query,
      timeDimensions: [
        {
          dimension: "cube_time_entries.dim_time_entry_timestamp_start",
          dateRange: [startDate, endDate],
        },
      ],
    };
  }

  if (teamName) {
    query = {
      ...query,
      filters: [
        {
          member: "cube_teams.dim_team_name",
          operator: "equals",
          values: [teamName],
        },
      ],
    };
  }

  const { resultSet, isLoading, error } = useCubeQuery(query);

  if (error) {
    return <div>{error.message}</div>;
  }
  if (isLoading || !resultSet) {
    return null;
  }

  const headers = [
    { header: t("registered_employee"), field: "cube_employees.dim_employee_name" },
    { header: t("registered_hours"), field: "cube_time_entries.sum_time_entry_amount_hours" },
  ];

  return (
    <Table
      columns={headers}
      value={resultSet.tablePivot().map((r) => headers.reduce((out, { field }) => ({ ...out, [field]: r[field] }), {}))}
    />
  );
};
