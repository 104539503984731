import { Button, LinkButton } from "@simplicate/ui";
import { MessageBus } from "@simplicate/utils";
import { useEffect, useState } from "react";

export const MessageBusActionButtons = () => {
  const [buttons, setButtons] = useState<ActionButtonConfig[]>([]);

  useEffect(() => MessageBus.subscribe("registerActionButton", setButtons), []);

  return buttons.map((config) => {
    switch (config.type) {
      case "button":
        return (
          <Button
            key={config.eventName}
            text={config.text}
            variant={config.variant}
            onClick={() => MessageBus.publish("actionButtonEvent", { eventName: config.eventName })}
            disabled={config.disabled}
          />
        );

      case "link":
        return (
          <LinkButton
            key={config.href}
            to={config.href}
            target={config.isDownload ? "_blank" : "_self"}
            rel={config.isDownload ? "noopener noreferrer" : ""}
            text={config.text}
            variant={config.variant}
            disabled={config.disabled}
          />
        );
    }
  });
};
