import { useFeatureFlag } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Checkbox } from "@simplicate/ui";
import styles from "./BetaFeaturePage.module.scss";
import { useBetaFeatures } from "./useBetaFeatures";

export const BetaFeaturePage = () => {
  const { t } = useTranslation("betafeatures");
  const { projectServiceNewUiFeature, setBetaFeatureEnabledProperty } = useBetaFeatures();
  const canToggleNewProjectServiceUIFeature = useFeatureFlag("projects-new-service-ui").enabled;

  return (
    <div className={styles.pageWrapper}>
      <h4 className={styles.title}>{t("page_title")}</h4>
      <div className={styles.pageContainer}>
        <div>{t("page_description")}</div>
        {canToggleNewProjectServiceUIFeature && (
          <div>
            <Checkbox
              testId="projects_new_service_ui_checkbox"
              name="projects_new_service_ui_checkbox"
              label={t("projects_new_service_ui_label")}
              onChange={(newValue) => setBetaFeatureEnabledProperty("projects-new-service-ui", newValue)}
              value={projectServiceNewUiFeature?.enabled ?? false}
            />
            <p className={styles.description}>{t("projects_new_service_ui_description")}</p>
          </div>
        )}
      </div>
    </div>
  );
};
