import { useTranslation } from "@simplicate/translations";
import { useMemo } from "react";

const keys = [
  "subscriptionSettings",
  "accountSettings",
  "accountingIntegrations",
  "administrationSettings",
  "usersAndGroupsSettings",
  "bccSettings",
  "mailManager",
  "notificationsSettings",
  "integrationsSettings",
  "apiSettings",
  "importSettings",
  "customFieldsSettings",
  "dossierSettings",
  "documentSettings",
  "paperlayoutSettings",
  "crm",
  "hrm",
  "sales",
  "projects",
  "invoices",
  "hoursReporting",
  "hrmReportingLeave",
  "hrmReportingAbsence",
  "reportingInvoicing",
  "reportingMrr",
  "projectsReportingRevenue",
  "projectsReportingProcess",
  "projectsReportingCombined",
  "salesReportingFunnel",
] as const;

export type TitleKey = (typeof keys)[number];

export const useTitleTranslator = (title: string | null) => {
  const { t } = useTranslation("top_bar");
  const translatedTitle = useMemo(
    () => ({
      subscriptionSettings: t("subscription_settings"),
      accountSettings: t("account_settings"),
      administrationSettings: t("administration_settings"),
      usersAndGroupsSettings: t("users_and_groups_settings"),
      bccSettings: t("bcc_settings"),
      mailManager: t("mail_manager"),
      notificationsSettings: t("notifications_settings"),
      integrationsSettings: t("integrations_settings"),
      apiSettings: t("api_settings"),
      importSettings: t("import_settings"),
      customFieldsSettings: t("custom_fields_settings"),
      dossierSettings: t("dossier_settings"),
      documentSettings: t("document_settings"),
      paperlayoutSettings: t("paperlayout_settings"),
      crm: t("crm_settings"),
      hrm: t("hrm_settings"),
      sales: t("sales_settings"),
      projects: t("projects_settings"),
      invoices: t("invoices_settings"),
      accountingIntegrations: t("accountancy_integrations"),
      hoursReporting: t("hours_reporting"),
      hrmReportingLeave: t("hrm_reporting_leave"),
      hrmReportingAbsence: t("hrm_reporting_absence"),
      reportingInvoicing: t("reporting_invoicing"),
      reportingMrr: t("reporting_mrr"),
      projectsReportingRevenue: t("projects_reporting_revenue"),
      projectsReportingProcess: t("projects_reporting_process"),
      projectsReportingCombined: t("projects_reporting_combined"),
      salesReportingFunnel: t("sales_reporting_funnel"),
    }),
    [t],
  );

  if (title === null || !keys.includes(title as TitleKey)) {
    return null;
  }

  return translatedTitle[title as TitleKey];
};
