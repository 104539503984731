import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetInvoiceQuery } from "@simplicate/api-client";
import { useAppSelector } from "@simplicate/state";
import { SubHeader } from "@simplicate/top-bar";
import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { colorCommonGrey200, lightColorTextError, lightColorTextSuccess } from "@simplicate-software/design-tokens";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RelationTag } from "../../RelationTag";

const PAID_STATUS_ID = "invoicestatus:35fd6229ed3d26d3";
const EXPIRED_STATUS_ID = "invoicestatus:2b46969975708018";

export function determineStatusColor(statusId: string) {
  switch (statusId) {
    case PAID_STATUS_ID:
      return lightColorTextSuccess;
    case EXPIRED_STATUS_ID:
      return lightColorTextError;
    default:
      return colorCommonGrey200;
  }
}

export const InvoiceSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("top_bar");
  const { IFRAME_CONTENT_LOADED } = useAppSelector(
    /* istanbul ignore next */ ({ state }) => state.iframe.latestMessages,
  );
  const location = useLocation();

  const { data: invoice, refetch } = useGetInvoiceQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    void refetch();
  }, [location.pathname, refetch]);

  useEffect(() => {
    // Refetch invoice data when iframe content is (re)loaded
    if (IFRAME_CONTENT_LOADED && IFRAME_CONTENT_LOADED.timestamp) {
      void refetch();
    }
  }, [IFRAME_CONTENT_LOADED, refetch]);

  const invoiceData = invoice?.data;

  if (!invoiceData) {
    return null;
  }

  const invoiceNumber = invoiceData.invoice_number;
  const relationName = invoiceData.organization?.name ?? invoiceData.person?.full_name;
  const organizationId = invoiceData.organization_id;
  const personId = invoiceData.person_id;

  const projectName = [invoiceData.projects?.at(0)?.project_number, invoiceData.projects?.at(0)?.name]
    .filter(Boolean)
    .join(". ");
  const invoiceName = [invoiceNumber, relationName].filter(Boolean).join(". ");
  const pageTitle = invoiceNumber ? invoiceName : projectName || t("draft_invoice");

  return (
    <SubHeader title={pageTitle}>
      <RelationTag organizationId={organizationId} personId={personId} name={relationName} />
      <Tag
        text={invoiceData.status.name}
        variant="color"
        color={determineStatusColor(invoiceData.status.id)}
        isElevated
      />
    </SubHeader>
  );
};
