import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Button, Dropdown, Icon, List, ListItem, Tag } from "@simplicate/ui";
import { useState } from "react";
import { ServiceNodeModel } from "../GroupedServicesManager.types";
import styles from "./ServiceRow.module.scss";
import { ServiceRowCell } from "./ServiceRowCell";

type ServiceRowProps = {
  node: ServiceNodeModel;
};

export const ServiceRow = ({ node: { id, text, data } }: ServiceRowProps) => {
  const { t } = useTranslation("grouped_services_manager");
  const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);

  const getInvoiceMethodLabel = (invoiceMethod: string) => {
    switch (invoiceMethod) {
      case "fixed":
        return t("invoice_method.fixed");
      case "hours":
        return t("invoice_method.hours");
      case "subscription_month":
        return t("invoice_method.subscription_month");
      case "subscription_quarter":
        return t("invoice_method.subscription_quarter");
      case "subscription_halve_year":
        return t("invoice_method.subscription_halve_year");
      case "subscription_year":
        return t("invoice_method.subscription_year");
      default:
        // istanbul ignore next - this should never happen once invoiceMethod is properly typed
        return "";
    }
  };

  return (
    <div className={styles.serviceRow} data-testid={`service-row-${id}`}>
      <ServiceRowCell variant="grip">
        <Icon icon="grip" />
      </ServiceRowCell>
      <ServiceRowCell variant="name">
        {text}
        {data?.invoiceMethod && <Tag size="small" text={getInvoiceMethodLabel(data.invoiceMethod)} variant="light" />}
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <NumberFormat value={data?.hoursTotalAmount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={data?.hoursTotalBudget?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={data?.purchaseTotalBudget?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={data?.price?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="button">
        {data?.duplicateCallback && (
          <>
            <Button
              variant="subtle"
              icon="ellipsisVertical"
              ariaLabel={t("actions")}
              onClick={/* istanbul ignore next */ () => setActionsDropdownOpen(!actionsDropdownOpen)}
              testId="actions-button"
            />
            <div className={styles.dropdownContainer}>
              <Dropdown
                isOpen={actionsDropdownOpen}
                onClickOutside={/* istanbul ignore next */ () => setActionsDropdownOpen(false)}
                width="auto"
                hasPadding={false}
              >
                <List>
                  {data?.duplicateCallback && (
                    <ListItem>
                      <Button
                        variant="invisible"
                        text={t("duplicate")}
                        icon="copy"
                        testId="duplicate-button"
                        onClick={() => {
                          /* istanbul ignore next */
                          if (data?.duplicateCallback) {
                            data.duplicateCallback();
                          }
                          setActionsDropdownOpen(false);
                        }}
                      />
                    </ListItem>
                  )}
                  {data?.deleteCallback && (
                    <ListItem>
                      <Button
                        testId="delete-button"
                        variant="invisibleDanger"
                        text={t("delete")}
                        icon="trash"
                        onClick={() => {
                          /* istanbul ignore next */
                          if (data?.deleteCallback) {
                            data.deleteCallback();
                          }
                          setActionsDropdownOpen(false);
                        }}
                      />
                    </ListItem>
                  )}
                </List>
              </Dropdown>
            </div>
          </>
        )}
      </ServiceRowCell>
    </div>
  );
};
