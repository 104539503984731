import { PropsWithChildren, useState, DOMAttributes } from "react";
import { Button } from "../Button";
import { Dropdown } from "../Dropdown";
import { IconName } from "../Icon";
import { List, ListItem } from "../List";
import styles from "./ActionDropdown.module.scss";

const ActionDropdown = ({ children, testId }: PropsWithChildren<{ testId?: string }>) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        testId={testId && `${testId}-button`}
        icon="ellipsisVertical"
        variant="subtle"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      />
      <div className={styles.dropdownWrapper}>
        <Dropdown
          testId={testId && `${testId}-dropdown`}
          width="auto"
          isOpen={dropdownOpen}
          onClickOutside={() => setDropdownOpen(false)}
          onKeyupOutside={() => setDropdownOpen(false)}
        >
          <List>{children}</List>
        </Dropdown>
      </div>
    </>
  );
};

type Variant = "invisible" | "invisibleDanger";

type ActionProps = {
  icon: IconName;
  variant?: Variant;
  label: string;
  onClick: DOMAttributes<HTMLButtonElement>["onClick"];
};

const Action = ({ icon, label, onClick, variant = "invisible" }: ActionProps) => {
  return (
    <ListItem>
      <Button icon={icon} text={label} variant={variant} onClick={onClick} />
    </ListItem>
  );
};

ActionDropdown.Action = Action;

export { ActionDropdown };
