import { useAppSelector } from "@simplicate/state";
import { SubHeader } from "@simplicate/top-bar";
import { useTitleTranslator, TitleKey } from "./helper";

export const GeneralSubHeader = () => {
  const headerTitle = useAppSelector(/* istanbul ignore next */ (root) => root.state.header).title;

  const title = useTitleTranslator(headerTitle as TitleKey);

  return <SubHeader title={title ?? headerTitle} testId="generalSubHeader" />;
};
