import { useGetBetaFeatureQuery, useSetBetaFeatureMutation } from "@simplicate/api-client";

export const useBetaFeatures = () => {
  const { data: betaFeatureResponse } = useGetBetaFeatureQuery();
  const [setBetaFeature] = useSetBetaFeatureMutation({
    /* c8 ignore next Mutation Results are not needed and can be excluded from coverage */
    selectFromResult: /* istanbul ignore next */ () => ({}),
  });

  const betaFeatures = betaFeatureResponse?.data;

  const projectServiceNewUiFeature = betaFeatures?.find(
    (feature) => feature.beta_feature === "projects-new-service-ui",
  );

  const setBetaFeatureEnabledProperty = (betaFeature: string, newValue: boolean) => {
    void setBetaFeature({
      beta_feature: betaFeature,
      enabled: newValue,
    });
  };

  return {
    projectServiceNewUiFeature,
    setBetaFeatureEnabledProperty,
  };
};
