import { useTranslation } from "@simplicate/translations";
import styles from "./Poc1Widget2.module.scss";

export const Poc1Widget2 = () => {
  const { t } = useTranslation("cube_dev");

  return (
    <div className={styles.cubeDevPageCard}>
      {t("Benchmark")}
      <br />
      <br />
      {t("--- unknown ---")}
    </div>
  );
};
